<template>
  <app-link class="author-preview-card" :href="author.link" :title="author.name">
    <div class="author-preview-card__image-holder">
      <app-picture
        :item="author.image"
        :alt="author.name"
        :title="author.name"
        :fallback-image="fallbackImage"
        class-img="author-preview-card__image"
        class-picture="author-preview-card__picture"
      />
    </div>
    <h3 class="author-preview-card__title">
      {{ author.name }}
    </h3>
  </app-link>
</template>

<script>
import AppPicture from '@/components/AppPicture';

export default {
  name: 'AuthorPreviewCard',
  components: { AppPicture },
  inheritAttrs: false,
  props: {
    author: { type: Object, required: true },
  },
  data() {
    return {
      fallbackImage: {
        type: 'fallback',
        x1: '/_ssr/img/not-image/Not_image_author_d.jpg',
        x2: '/_ssr/img/not-image/Not_image_author_d@2x.jpg',
        webpX1: null,
        webpX2: null,
      },
    };
  },
};
</script>

<style lang="less">
.author-preview-card {
  width: 164px;
  display: block;
  text-decoration: none;
  color: @black;
  transition: @A color;

  &__image-holder {
    width: 134px;
    height: 134px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0 auto;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
    transition: @A transform;
  }

  &__title {
    .p;
    .overflow-ellipsis-lines(2);

    margin: @size-x5 0 0;
    height: 48px;
    font-weight: 600;
    text-align: center;
    color: currentColor;
  }

  &:hover {
    color: @link-hover;
  }

  &:hover &__image {
    transform: scale(1.1);
  }

  &:active {
    color: @link-visited;
  }
}
</style>
