<template>
  <ul class="tabs-header">
    <li
      v-for="tab in tabs"
      :key="tab.key"
      class="tabs-header__item"
    >
      <button-control
        class="tabs-header__link"
        :class="[
          tab.key === activeTab ? '_gray' : '_gray-light',
          isMobile ? '' : '_large'
        ]"
        @click="$emit('activate', tab.key)"
      >
        {{ tab.title }}
      </button-control>
    </li>
  </ul>
</template>

<script>
import { ButtonControl } from '@book24/ui-components';
import { checkMobile } from '@/utils/deviceCheck';

export default {
  name: 'TabsHeader',
  components: {
    ButtonControl,
  },
  props: {
    tabs: { type: Object, required: true },
    activeTab: { type: String, required: true },
  },
  setup() {
    const isMobile = checkMobile();

    return { isMobile };
  },
};
</script>

<style lang="less">
.tabs-header {
  .list-reset;

  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  &__item {
    margin-left: @size-x3;

    &:first-child {
      margin-left: 0;
    }
  }

  &__link {
    border: none;
  }
}
</style>
