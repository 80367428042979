import { analyticSelectPromotion } from '@/utils/analytics/analyticEvents';
import { BANNER_SLOTS } from '@/utils/constants/analytics/bannerSlotsConstant';

export const useAnalyticBanners = () => {
  const topBannerSlot = BANNER_SLOTS.top;
  const mainSliderSlot = BANNER_SLOTS.mainSlider;
  const nearSlot = BANNER_SLOTS.near;
  const highlightBannerSlot = BANNER_SLOTS.highlight;
  const categorySaleBannerSlot = BANNER_SLOTS.categorySale;
  const catalogSliderSlot = BANNER_SLOTS.catalog;
  const brandSliderSlot = BANNER_SLOTS.brand;
  const saleSliderSlot = BANNER_SLOTS.sale;

  /**
   * Отправляет в аналитику клик по баннеру
   * @param bannerName
   * @param bannerId
   * @param bannerUrl
   * @param bannerIndex
   * @param bannerSlot
   */
  const handlerClickBanner = ({
    bannerName,
    bannerId,
    bannerLink,
    bannerIndex,
    bannerSlot,
  }) => {
    analyticSelectPromotion([{
      name: bannerName,
      index: bannerIndex,
      id: bannerId,
      slot: bannerSlot,
      link: bannerLink,
    }]);
  };

  return {
    topBannerSlot,
    mainSliderSlot,
    nearSlot,
    highlightBannerSlot,
    categorySaleBannerSlot,
    catalogSliderSlot,
    brandSliderSlot,
    saleSliderSlot,
    handlerClickBanner,
  };
};
