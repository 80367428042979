<template>
  <app-section-skeleton
    is-title
    :is-container="isContainer"
    :is-full-width="isFullWidth"
  >
    <div class="carousel-skeleton authors-list-slider">
      <div
        v-for="item in 6"
        :key="`authors-list-slider-skeleton-${item}`"
        class="carousel-skeleton__item authors-list-slider__item"
      >
        <author-preview-card-skeleton />
      </div>
    </div>
  </app-section-skeleton>
</template>

<script>
import AppSectionSkeleton from '@/components/AppSection/skeleton';
import AuthorPreviewCardSkeleton from '@/components/AuthorPreviewCard/skeleton';

export default {
  name: 'PopularAuthorsSliderSkeleton',
  components: {
    AppSectionSkeleton,
    AuthorPreviewCardSkeleton,
  },
  props: {
    isContainer: { type: Boolean, default: true },
    isFullWidth: { type: Boolean, default: false },
  },
};
</script>

<style lang="less" scoped>
.carousel-skeleton {
  display: flex;

  &__item {
    flex: 0 0 auto;
    width: calc(100% / 6);
    position: relative;
    counter-increment: embla;

    @media @tablet {
      width: calc(100% / 3);

      &:nth-child(-n+3) {
        display: none;
      }
    }
  }
}
</style>
