<template>
  <content-placeholders rounded centered class="author-preview-card">
    <content-placeholders-img class="author-preview-card__image-holder" />
    <content-placeholders-text
      class="author-preview-card__title"
      :lines="2"
      style="max-height: 48px;"
    />
  </content-placeholders>
</template>

<script>
export default {
  name: 'AuthorPreviewCardSkeleton',
};
</script>
