import { computed, reactive, toRefs } from '@nuxtjs/composition-api';

import { captureException } from '@sentry/browser';

import useHttpClient from '@/compositions/useHttpClient';

const EXCLUSIVE_SELECTION_ID = '6792664';

const useProductsExclusive = () => {
  const { selection } = useHttpClient();

  const localState = reactive({
    productsExclusive: [],
    isProductsExclusiveLoading: false,
    isEmptyProducts: false,
    pagination: {
      total: 0,
      count: 0,
      perPage: 30,
      totalPages: 1,
      currentPage: 1,
    },
  });

  const showLoadMore = computed({
    get: () => (localState.pagination.totalPages > 1
      && localState.pagination.currentPage < localState.pagination.totalPages),
    set: () => {},
  });

  const getProductsExclusive = async (options = {}) => {
    const { isOverwriteProducts = true, pageNumber = 1, pageSize = 100 } = options;
    localState.isProductsExclusiveLoading = true;

    await selection.getSelectionProductsList({
      selectionId: EXCLUSIVE_SELECTION_ID,
      isGetProductText: true,
      isGetSeries: true,
      isGetProductStats: false,
      pageSize,
      pageNumber,
    })
      .then((res) => {
        localState.pagination = res.pagination;
        localState.isEmptyProducts = localState.pagination.currentPage === 1 && !res.items.length;
        const itemsWithoutAdult = res.items
          .filter(item => !item.isAdult)
          .reverse();
        localState.productsExclusive = isOverwriteProducts
          ? itemsWithoutAdult
          : [...localState.productsExclusive, ...itemsWithoutAdult];
      })
      .catch((error) => {
        localState.productsExclusive = [];
        captureException(error);
      })
      .finally(() => {
        localState.isProductsExclusiveLoading = false;
      });
  };

  const loadMore = async (payload = {}) => {
    const nextPageNumber = localState.pagination.currentPage + 1;

    await getProductsExclusive({
      ...payload,
      pageNumber: nextPageNumber,
    });
  };

  return {
    ...toRefs(localState),
    showLoadMore,
    getProductsExclusive,
    loadMore,
  };
};

export default useProductsExclusive;
