<template>
  <div class="app-journal-list">
    <div
      v-for="(item, index) of rows"
      :key="index"
      class="app-journal-list__item"
    >
      <tabs-header
        :tabs="item.tabs"
        :active-tab="item.activeTab"
        @activate="setActiveTab($event, index)"
      />
      <app-journal-skeleton v-if="item.isLoading" />
      <journal-grid
        v-else-if="!item.isLoading && item.list.length"
        :list="item.list"
        :is-homepage="true"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import useJournalSection from '@/compositions/mainPage/useJournalSection';

import TabsHeader from '@/components/AppJournal/TabsHeader';
import AppJournalSkeleton from '@/components/AppJournal/skeleton';
import JournalGrid from '@/components/AppJournalPage/JournalGrid';

export default defineComponent({
  name: 'AppJournalList',
  components: {
    TabsHeader,
    AppJournalSkeleton,
    JournalGrid,
  },
  setup() {
    const journalSection = useJournalSection();

    return {
      ...journalSection,
    };
  },
});
</script>

<style lang="less">
.app-journal-list {
  &__item {
    @media @mobile {
      margin-bottom: @size-x6;
    }

    margin-bottom: @size-x10;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
