<template>
  <app-link
    class="rating-common-card-mobile"
    :href="`/rating/${link}/`"
    :class="{ '_tab': isTab }"
  >
    <div class="rating-common-card-mobile__info">
      <span class="rating-common-card-mobile__text">{{ text }}</span>
      <img
        class="rating-common-card-mobile__triangle"
        :src="`/_ssr/img/ratings/triangle.svg`"
        alt=""
      />
    </div>
    <h3 class="rating-common-card-mobile__title">
      {{ title }}
    </h3>
  </app-link>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'RatingCommonCard',
  inheritAttrs: false,
  props: {
    link: { type: String, default: '' },
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    isTab: { type: Boolean, default: false },
  },
});
</script>

<style lang="less">
.rating-common-card-mobile {
  .a-reset;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: @size-x4 @size-x3 @size-x4 @size-x5;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px rgba(8, 35, 48, 0.24);
  border-radius: @main-size;

  &__title {
    .h-reset;
    .h4-mobile;
  }

  &__info {
    position: relative;
    flex-shrink: 0;
    font-size: 22px;
    line-height: 44px;
    font-weight: 700;
  }

  &._tab &__info {
    margin-right: @size-x2;
  }

  &__text {
    z-index: 3;
  }

  &__triangle {
    position: absolute;
    z-index: -1;
    width: 83px;
    height: 39px;
    left: -@size-x3;
    top: 6px;
  }
}
</style>
