<template>
  <content-placeholders
    rounded
    centered
  >
    <div class="snippet-card-skeleton">
      <div class="snippet-card-skeleton__wrapper">
        <content-placeholders-img class="snippet-card-skeleton__image" />
        <content-placeholders-text
          :lines="1"
          class="snippet-card-skeleton__name"
        />
      </div>
    </div>
  </content-placeholders>
</template>

<script>
export default {
  name: 'SnippetCardSkeleton',
};
</script>

<style lang="less" scoped>
.snippet-card-skeleton {
  position: relative;
  width: 100%;
  height: 0;
  display: flex;
  padding-bottom: 100%;

  &__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: @size-x4 @size-x4 2px;
    text-align: center;
    background: #fff;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 0;
  }

  &__name {
    flex: none;
    margin-top: @size-x3;
  }
}
</style>
