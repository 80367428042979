import {
  reactive,
  toRefs,
  computed,
} from '@nuxtjs/composition-api';

import { captureException } from '@sentry/browser';
import useHttpClient from '@/compositions/useHttpClient';

import formattedRating from '@/utils/formatters/formattedRating';

const useRatings = () => {
  const httpClient = useHttpClient();

  const params = reactive({
    commonCategory: [],
    publishingCategory: [],
    byCategoriesList: [],
    isLoadingRatings: false,
    isLoadingProducts: false,
    pageSize: 30,
    products: {
      items: [],
      isEmptyProducts: false,
    },
    pagination: {
      total: 0,
      count: 0,
      perPage: 30,
      totalPages: 1,
      currentPage: 1,
    },
    ratingCategoryName: '',
    ratingCategoryId: '',
    seoMeta: {},
  });

  const isVisibleCommonRatingsList = computed({
    get: () => !!params.commonCategory?.ratings?.length,
    set: () => {},
  });

  const isVisiblePublishingRatingsList = computed({
    get: () => !!params.publishingCategory?.ratings?.length,
    set: () => {},
  });

  const isVisibleByCategoriesList = computed({
    get: () => !!params.byCategoriesList?.ratings?.length,
    set: () => {},
  });

  const getRatings = () => {
    params.isLoadingRatings = true;
    return httpClient.rating.getRatings()
      .then((list) => {
        const {
          common,
          publishers,
          byCategories,
        } = formattedRating(list);
        params.publishingCategory = publishers;
        params.commonCategory = common;
        params.byCategoriesList = byCategories;
      })
      .catch((e) => {
        params.publishingCategory = [];
        params.commonCategory = [];
        params.byCategoriesList = [];
        captureException(e.message);
        return Promise.reject(e);
      })
      .finally(() => params.isLoadingRatings = false);
  };

  const getRatingsProducts = (payload = {}) => {
    const {
      id,
      pageNumber,
      filterPreset,
      filter,
    } = payload;

    return httpClient.rating.getRatingsProducts({
      id,
      pageNumber,
      pageSize: params.pageSize,
      filterPreset,
      filter,
    })
      .then(({ items, pagination }) => {
        params.products.isEmptyProducts = !items.length;
        params.products.items = items;
        params.pagination = pagination;
      })
      .catch(e => Promise.reject(e));
  };

  const getRatingsDetailsAndProducts = (payload = {}) => {
    params.isLoadingProducts = true;
    const {
      code,
      pageNumber,
      filterPreset,
      filter,
    } = payload;

    return httpClient.rating.getRatingDetails(code)
      .then(({ name, id, seoMeta }) => {
        params.seoMeta = seoMeta;
        params.ratingCategoryName = name;
        params.ratingCategoryId = id;
        return getRatingsProducts({
          id,
          pageNumber,
          pageSize: params.pageSize,
          filterPreset,
          filter,
        });
      })
      .catch(e => Promise.reject(e))
      .finally(() => params.isLoadingProducts = false);
  };

  return {
    ...toRefs(params),
    isVisibleCommonRatingsList,
    isVisiblePublishingRatingsList,
    isVisibleByCategoriesList,
    getRatings,
    getRatingsDetailsAndProducts,
    getRatingsProducts,
  };
};

export default useRatings;
