<script>
import JournalItem from '@/components/AppJournalPage/JournalItem';

export default {
  name: 'JournalItemMobile',
  functional: true,
  render(createElement, context) {
    return createElement(
      JournalItem,
      {
        ...context.data,
        class: ['_mobile'],
      },
      context.children,
    );
  },
};
</script>

<style lang="less">
.journal-item {
  &._mobile {
    width: 100%;
    height: 169px;
    margin-bottom: @size-x4;

    .journal-item__title {
      .h3-mobile;

      margin-bottom: @main-size;
    }

    .journal-item__content {
      padding: @size-x4;

      &._hasDescription {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .journal-item__desc,
        .journal-item__title {
          margin: 0;
        }
      }
    }

    .journal-item__desc {
      .overflow-ellipsis-lines(2);
    }
  }
}
</style>
