<template>
  <div class="embla-slide-authors">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'EmblaSlideAuthors',
};
</script>

<style lang="less">
.embla-slide-authors {
  flex: 0 0 auto;
  width: calc(100% / 6);
  position: relative;
  counter-increment: embla;

  @media @tablet {
    width: calc(100% / 3);
  }
}
</style>
