<template>
  <authors-slider-skeleton v-if="isLoading" />
  <authors-slider
    v-else-if="!isLoading && items && items.length"
    title="Популярные авторы"
    :more-link="{title: 'Все авторы', link: '/author/'}"
    :items="items"
  />
</template>

<script>
import { defineComponent, onMounted } from '@nuxtjs/composition-api';

import useAuthorsList from '@/compositions/shelfs/useAuthorsList';

import AuthorsSlider from '@/components/AuthorsSlider';
import AuthorsSliderSkeleton from '@/components/AuthorsSlider/skeleton';

export default defineComponent({
  name: 'PopularAuthorsSlider',
  components: {
    AuthorsSlider,
    AuthorsSliderSkeleton,
  },
  setup() {
    const authors = useAuthorsList();

    onMounted(() => {
      authors.getAuthorsList({ popular: true });
    });

    return {
      items: authors.authorsList,
      isLoading: authors.isLoadingAuthorsList,
    };
  },
});
</script>
