<script>
import { checkMobile } from '@/utils/deviceCheck';

import AppJournalMobile from '@/components/AppJournal/mobile.vue';
import AppJournalDesktop from '@/components/AppJournal/desktop.vue';

const isMobile = checkMobile();

export default {
  functional: true,
  render(createElement, context) {
    return createElement(
      isMobile ? AppJournalMobile : AppJournalDesktop,
      context.data,
      context.children,
    );
  },
};
</script>
