/// <reference path="../../services/v1/CatalogService/typedefs.js" />

import {
  reactive,
  toRefs,
} from '@nuxtjs/composition-api';

import useHttpClient from '@/compositions/useHttpClient';

/**
 * @param {Object} props
 * @param {String} props.snippetId
 */
const useSnippets = (props) => {
  const httpClient = useHttpClient();
  const { snippetId } = props;

  const params = reactive({
    isLoading: false,
    id: '',
    title: '',
    snippets: [],
  });

  /**
   * Получаем Блок сниппетов
   * @return {Promise<SnippetList>}
   */
  const getSnippetList = () => new Promise((resolve) => {
    params.isLoading = true;
    const dataRequest = { snippetId };

    return httpClient.catalog.getSnippets(dataRequest)
      .then((snippetData) => {
        params.id = snippetData.id;
        params.title = snippetData.title;
        params.snippets = snippetData.snippets;
        return resolve(snippetData);
      })
      .catch(() => resolve(null))
      .finally(() => {
        params.isLoading = false;
      });
  });

  return {
    ...toRefs(params),
    getSnippetList,
  };
};

export default useSnippets;
