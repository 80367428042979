<template>
  <app-section-skeleton is-title is-container>
    <div class="snippet-list">
      <snippet-card-skeleton
        v-for="item in 6"
        :key="`${getId()}-${item}`"
      />
    </div>
  </app-section-skeleton>
</template>

<script>
import { nanoid } from 'nanoid';
import AppSectionSkeleton from '@/components/AppSection/skeleton';
import SnippetCardSkeleton from '@/components/SnippetCard/skeleton';

export default {
  name: 'SnippetListSkeleton',
  components: {
    AppSectionSkeleton,
    SnippetCardSkeleton,
  },
  methods: {
    getId() {
      return nanoid();
    },
  },
};
</script>

<style lang="less" scoped>
.snippet-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: @size-x8;

  @media @desktop {
    gap: @size-x6;
  }

  @media @tablet {
    gap: @size-x5;
  }
}
</style>
