<template>
  <div class="rating-common-list" :class="{_mobile: isMobile, _shelf: isShelf}">
    <h2 v-if="!isShelf" class="rating-common-list__title">
      {{ title }}
    </h2>
    <ul class="rating-common-list__list">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="rating-common-list__card"
      >
        <rating-common-card
          :link="item.code"
          :title="item.name"
          :text="item.text"
          :triangle-version="item.position"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import RatingCommonCard from '@/components/ratingPage/RatingCommonCard/index.js';

export default defineComponent({
  name: 'RatingCommonList',
  components: {
    RatingCommonCard,
  },
  props: {
    items: { type: Array, default: () => ([]) },
    title: { type: String, default: 'Общие рейтинги' },
    isMobile: { type: Boolean, default: false },
    isShelf: { type: Boolean, default: false },
  },
});
</script>

<style lang="less">
.rating-common-list {
  .container-d;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: url(~'/_ssr/img/ratings/background-cubes.png') no-repeat center;
  padding-bottom: 72px;
  padding-top: @size-x4;

  @media @tablet {
    justify-content: center;
    background: url(~'/_ssr/img/ratings/background-cubes-tablet.png') no-repeat center;
    background-size: cover;
  }

  &._mobile {
    .container-m;

    background: url(~'/_ssr/img/ratings/background-cubes-mobile.png') no-repeat center;
    background-size: cover;
    padding-bottom: 0;
    padding-top: 0;
    align-items: flex-start;
  }

  &._shelf {
    padding: 0;
    background: none;
    min-width: unset;
  }

  &__list {
    .list-reset;

    display: flex;
    width: 100%;
    justify-content: center;

    @media @tablet {
      flex-wrap: wrap;
    }
  }

  &._mobile &__list {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  &__card {
    background-color: @white;
    z-index: 1;
    width: calc(25% - 20px);
    max-width: 288px;
    height: 208px;
    margin: 0 10px;
    border-radius: @main-size;

    @media @desktop {
      max-width: 246px;
    }

    @media @tablet {
      width: calc(33% - 24px);
      max-width: 298px;
      margin-right: @size-x6;

      &:nth-child(n+4) {
        margin-top: @size-x7;
        width: 47%;
        max-width: 458px;
      }

      &:nth-child(3),
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &._mobile &__card {
    width: 100%;
    max-width: 100%;
    height: 72px;
    margin-bottom: @size-x2;
    margin-right: 0;
    margin-left: 0;
    z-index: 2;

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(n+4) {
      margin-top: 0;
      width: 100%;
      max-width: 100%;
    }
  }

  &._shelf &__card {
    max-width: unset;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @media @tablet {
      width: calc(33% - 22px);

      &:nth-child(n+4) {
        width: 48%;
      }

      &:nth-child(4) {
        margin-left: 0;
      }
    }
  }

  &__title {
    .h-reset;
    .h2-desktop;

    text-align: center;
    margin-bottom: 69px;
  }

  &._mobile &__title {
    .h3-mobile;

    margin-bottom: @size-x4;
  }
}
</style>
