<template>
  <app-section-skeleton is-title>
    <div class="shelf-rating-skeleton rating-common-list _shelf">
      <ul class="rating-common-list__list">
        <li
          v-for="item in 5"
          :key="`rating-common-list__card-${item}`"
          class="rating-common-list__card"
        >
          <content-placeholders class="rating-common-card" rounded>
            <content-placeholders-text
              :lines="1"
              class="shelf-rating-skeleton__text"
            />
            <content-placeholders-text
              :lines="1"
              class="shelf-rating-skeleton__text"
            />
          </content-placeholders>
        </li>
      </ul>
    </div>
  </app-section-skeleton>
</template>

<script>
import AppSectionSkeleton from '@/components/AppSection/skeleton';

export default {
  name: 'ShelfRatingSkeleton',
  components: {
    AppSectionSkeleton,
  },
};
</script>

<style lang="less" scoped>
.shelf-rating-skeleton {
  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
</style>
