<template>
  <app-link
    class="rating-common-card"
    :class="{'_tab': isTab}"
    :href="`/rating/${link}/`"
  >
    <h3 class="rating-common-card__title">
      {{ title }}
    </h3>
    <div class="rating-common-card__info">
      <span class="rating-common-card__text">{{ text }}</span>
      <img
        class="rating-common-card__triangle"
        :class="`_vers${triangleVersion}`"
        :src="`/_ssr/img/ratings/${triangleImg}.svg`"
        alt=""
      />
    </div>
  </app-link>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'RatingCommonCard',
  inheritAttrs: false,
  props: {
    link: { type: String, default: '' },
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    triangleVersion: { type: Number, default: 1 },
    isTab: { type: Boolean, default: false },
  },
  setup(props) {
    const triangleImg = computed(() => {
      let result = 'triangle';
      const isSmallTriangleVersion = value => value > 2;
      if (isSmallTriangleVersion(props.triangleVersion)) {
        result = 'triangle-small';
      }

      return result;
    });

    return {
      triangleImg,
    };
  },
});
</script>

<style lang="less">
.rating-common-card {
  .a-reset;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: @size-x6 @size-x4 62px @size-x4;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px rgba(8, 35, 48, 0.24);
  border-radius: @main-size;

  &._tab {
    padding: @size-x3;
    justify-content: flex-start;
  }

  &__title {
    .h-reset;
    .h3-desktop(30px);

    margin-bottom: @size-x5;
    text-align: center;
  }

  &._tab &__title {
    .small;

    font-weight: 700;
  }

  &__info {
    position: relative;
    font-size: 44px;
    line-height: 44px;
    font-weight: 600;
  }

  &._tab &__info {
    font-size: 22px;
    line-height: 24px;
  }

  &__text {
    z-index: 3;
  }

  &__triangle {
    position: absolute;
    z-index: -1;

    &._vers1 {
      width: 163px;
      height: 82px;
      left: -20px;
      top: -16px;

      @media @desktop {
        width: 141px;
      }
    }

    &._vers2 {
      width: 126px;
      height: 63px;
      transform: rotate(52deg);
      left: -29px;
      top: -6px;

      @media @desktop {
        width: 120px;
        height: 58px;
      }
    }

    &._vers3 {
      width: 62px;
      height: 83px;
      right: 0;
      top: -28px;
    }

    &._vers4 {
      width: 62px;
      height: 83px;
      left: -7px;
      top: -14px;
      transform: rotate(194deg);
    }

    &._vers5 {
      width: 62px;
      height: 83px;
      right: 19px;
      top: -17px;
      transform: rotate(78deg);
    }
  }

  &._tab &__triangle._vers1 {
    width: 90px;
    height: 39px;
    left: -20px;
    top: -6px;
  }

  &._tab &__triangle._vers2 {
    width: 101px;
    height: 35px;
    left: -38px;
    top: 0;
  }

  &._tab &__triangle._vers3 {
    width: 67px;
    height: 50px;
    left: -15px;
    top: -14px;
  }

  &._tab &__triangle._vers4 {
    width: 43px;
    height: 50px;
    left: -7px;
    top: -10px;
  }

  &._tab &__triangle._vers5 {
    width: 47px;
    height: 47px;
    left: 33px;
    top: -13px;
  }
}
</style>
