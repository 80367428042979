import { ref } from '@nuxtjs/composition-api';
import useHttpClient from '@/compositions/useHttpClient';

const useMainBanners = () => {
  const httpClient = useHttpClient();

  const list = ref([]);
  const isLoading = ref(true);

  const getMainBanners = async () => {
    isLoading.value = true;
    await httpClient.content.getBannersMainSlider()
      .then((data) => {
        list.value = data;
      })
      .catch(() => {
        list.value = [];
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  return {
    list,
    isLoading,
    getMainBanners,
  };
};

export default useMainBanners;
