import { checkMobile } from '@/utils/deviceCheck';

import CardMobile from '@/components/ratingPage/RatingCommonCard/mobile.vue';
import CardDesktop from '@/components/ratingPage/RatingCommonCard/desktop.vue';

export default {
  functional: true,
  render(createElement, context) {
    return createElement(
      checkMobile() ? CardMobile : CardDesktop,
      context.data,
      context.children,
    );
  },
};
