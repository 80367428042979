import { computed, reactive, toRefs } from '@nuxtjs/composition-api';
import useProducts from '@/compositions/catalog/useProducts';

const useProductsBestPrice = () => {
  const { getProductsList } = useProducts();

  const localState = reactive({
    products: [],
    isLoading: false,
    isEmptyProducts: false,
    pagination: {
      total: 0,
      count: 0,
      perPage: 30,
      totalPages: 1,
      currentPage: 1,
    },
  });

  const showLoadMore = computed({
    get: () => (localState.pagination.totalPages > 1
      && localState.pagination.currentPage < localState.pagination.totalPages),
    set: () => {},
  });

  const getProducts = async (options = {}) => {
    const { isOverwriteProducts = true, pageNumber = 1, pageSize = 15 } = options;
    localState.isLoading = true;
    const dataRequest = {
      filterPreset: 'internal-default,section-new-books,bestprice',
      sortPreset: '-popular',
      pageSize,
      pageNumber,
    };
    await getProductsList({
      ...dataRequest,
      ...options,
    })
      .then((res) => {
        localState.pagination = res.pagination;
        localState.isEmptyProducts = localState.pagination.currentPage === 1 && !res.items.length;
        const itemsWithoutAdult = res.items.filter(item => !item.isAdult);
        localState.products = isOverwriteProducts
          ? itemsWithoutAdult
          : [...localState.products, ...itemsWithoutAdult];
      })
      .catch(() => {
        localState.products = [];
      })
      .finally(() => {
        localState.isLoading = false;
      });
  };

  const loadMore = async (payload = {}) => {
    const nextPageNumber = localState.pagination.currentPage + 1;

    await getProducts({
      ...payload,
      pageNumber: nextPageNumber,
    });
  };

  return {
    ...toRefs(localState),
    showLoadMore,
    getProducts,
    loadMore,
  };
};

export default useProductsBestPrice;
