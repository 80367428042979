<template>
  <app-section
    title="Журнал «Что читать»"
    link-more="/journal/"
    class="app-journal-mobile"
  >
    <app-journal-list />
  </app-section>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import AppSection from '@/components/_mobile/AppSection';
import AppJournalList from '@/components/AppJournal/AppJournalList';

export default defineComponent({
  name: 'AppJournal',
  components: {
    AppSection,
    AppJournalList,
  },
});
</script>
