<template>
  <app-section
    :title="title"
    :more-link="moreLink"
    :is-container="isContainer"
    :is-full-width="isFullWidth"
  >
    <embla-carousel
      class="authors-list-slider"
      :options="{ containScroll: 'keepSnaps', loop: true }"
    >
      <embla-slide-authors
        v-for="author in items"
        :key="author.name"
        class="authors-list-slider__item"
      >
        <author-preview-card :author="author" />
      </embla-slide-authors>
    </embla-carousel>
  </app-section>
</template>

<script>
import AppSection from '@/components/AppSection';
import EmblaCarousel from '@/components/EmblaCarousel';
import EmblaSlideAuthors from '@/components/EmblaSlideAuthors';
import AuthorPreviewCard from '@/components/AuthorPreviewCard';

export default {
  name: 'AuthorsSlider',
  components: {
    AppSection,
    EmblaCarousel,
    EmblaSlideAuthors,
    AuthorPreviewCard,
  },
  props: {
    title: { type: String, default: '' },
    moreLink: { type: Object, default: () => ({}) },
    items: { type: Array, default: () => ([]) },
    isContainer: { type: Boolean, default: true },
    isFullWidth: { type: Boolean, default: false },
  },
};
</script>

<style lang="less">
.authors-list-slider {
  margin-left: -@size-x3;

  &__item {
    padding-left: @size-x3;
    display: flex;
    justify-content: center;
  }
}
</style>
