<template>
  <div
    class="snippet-card"
    :class="{ _mobile: isMobile }"
    @click="handlerClickSnippet"
  >
    <app-link
      :href="snippet.url"
      class="snippet-card__wrapper"
    >
      <div class="snippet-card__image">
        <img
          :src="snippet.cover"
          alt="Изображение сниппета"
          loading="lazy"
        />
      </div>
      <div class="snippet-card__name">
        {{ snippet.name }}
      </div>
    </app-link>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import { useAnalyticSnippets } from '@/compositions/analytics/useAnalyticSnippets';

export default defineComponent({
  name: 'SnippetCard',
  props: {
    isMobile: { type: Boolean, default: false },
    snippet: { type: Object, default: () => ({}) },
    snippetIndex: { type: Number, default: 1 },
    snippetTitle: { type: String, default: '' },
  },
  setup(props) {
    const { handlerClickSnippet } = useAnalyticSnippets(props);
    return { handlerClickSnippet };
  },
});
</script>

<style lang="less" scoped>
.snippet-card {
  position: relative;
  width: 100%;
  height: 0;
  display: flex;
  padding-bottom: 100%;

  &__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: @size-x5;
    border: 1px solid @gray-super-light;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    background: #fff;
    color: #000;
    transition: background-color @A;

    &:hover {
      background-color: @gray-back-2;
    }

    &:active,
    &._tap {
      background-color: @gray-back;
    }
  }

  &._mobile &__wrapper {
    padding: @size-x4 @size-x4 @size-x3;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 0;

    & img {
      width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: contain;
      color: transparent;
    }
  }

  &__name {
    flex: none;
    margin-top: @size-x3;

    .p(24px);
    .overflow-ellipsis-lines;
  }

  &._mobile &__name {
    .small(20px);
  }
}
</style>
