import { computed } from '@nuxtjs/composition-api';

import { MAIN_URL } from '@/utils/constants/globalConst';
import { analyticSelectSnippet } from '@/utils/analytics/analyticEvents';

export const useAnalyticSnippets = (props) => {
  const snippetNames = computed(() => props.snippets
    .map(snippet => `${snippet.name}`)
    .join('|'));
  const snippetLinks = computed(() => props.snippets
    .map(snippet => `${MAIN_URL}${snippet.url}`)
    .join('|'));

  const handlerClickSnippet = () => {
    analyticSelectSnippet([{
      title: props.snippetTitle,
      index: props.snippetIndex,
      names: props.snippet.name,
      links: `${MAIN_URL}${props.snippet.url}`,
    }]);
  };

  return {
    snippetNames,
    snippetLinks,
    handlerClickSnippet,
  };
};
