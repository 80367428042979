<template>
  <!-- eslint-disable vue/singleline-html-element-content-newline -->
  <app-section
    id="about"
    is-tag-title="h1"
    title="Интернет-магазин книг Book24"
    is-centered
  >
    <div class="main-page-about">
      <h2 class="main-page-about__desc">
        Book24.ru – официальный книжный магазин крупнейшей в России издательской группы Эксмо-АСТ.
      </h2>
      <div class="main-page-about__text">
        <section class="main-page-about__column">
          <p class="main-page-about__paragraph">
            В нашем каталоге представлено свыше 60 000 товаров издательств
            <app-link href="/brand/eksmo/">«Эксмо»</app-link>,
            <app-link href="/brand/ast/">«АСТ»</app-link>,
            <app-link href="/brand/mif/">«Манн, Иванов и Фербер»</app-link>,
            издательских брендов
            <app-link href="/brand/bombora/">«БОМБОРА»</app-link>,
            <app-link href="/brand/corpus/">«Corpus»</app-link>,
            <app-link href="/brand/mainstream/">«Mainstream»</app-link>,
            <app-link href="/brand/shubina/">«Редакция Елены Шубиной»</app-link>,
            <app-link href="/brand/willie-winkie/">«Вилли-Винки»</app-link>,
            <app-link href="/brand/fanzon/">«Fanzon»</app-link>,
            <app-link href="/brand/komilfo/">«Комильфо»</app-link>,
            <app-link href="/brand/kanc-eksmo/">«Канц-Эксмо»</app-link> и др.
          </p>
          <br />
          <p class="main-page-about__paragraph">
            Book24.ru одним из первых получает книжные новинки ведущих российских издательств
            и предлагает их покупателям
            <app-link href="/best-price/">по лучшей цене</app-link>.
            На книги, которые только планируются к выходу, доступен
            <app-link href="/predzakaz/">предзаказ</app-link>.
          </p>
          <br />
          <p class="main-page-about__paragraph">
            В нашем ассортименте вы найдёте художественную и бизнес-литературу,
            товары для хобби и творчества, детские книги, учебники, научно-популярную литературу,
            офисные и канцелярские принадлежности, издания с автографами известных писателей
            и другую продукцию.
          </p>
        </section>
        <section class="main-page-about__column">
          <p class="main-page-about__paragraph">
            Мы регулярно проводим интересные акции и конкурсы,
            участие в которых гарантирует выгодные покупки.
            Наши клиенты также могут рассчитывать на персональные скидки, подарки, кэшбэк по
            <app-link href="/cashback/">программе лояльности</app-link>.
            Специальные скидки доступны и сотрудникам
            <app-link href="/b2b/">компаний-партнёров</app-link>.
          </p>
          <p class="main-page-about__paragraph">
            Book24.ru не только предлагает новинки и бестселлеры на любой вкус,
            но и помогает определиться с выбором. Для этого на сайте публикуются
            <app-link href="/people-and-books/">списки книг от известных людей</app-link>,
            <app-link href="/podborki/">тематические подборки</app-link>,
            <app-link href="/rating/">рейтинги</app-link>,
            обзоры и отзывы реальных покупателей.
          </p>
          <p class="main-page-about__paragraph">
            У нас вы можете выбрать и заказать товары в любое время суток и оформить
            <app-link href="/delivery/">доставку</app-link> удобным способом.
          </p>
          <p class="main-page-about__paragraph">
            Сотрудники book24.ru всегда готовы оперативно ответить на ваши вопросы
            и помочь сделать покупки более комфортными.
          </p>
        </section>
      </div>
      <p class="main-page-about__paragraph _welcome">
        Добро пожаловать на book24.ru!
      </p>
    </div>
  </app-section>
</template>

<script>
import AppSection from '@/components/AppSection';

export default {
  name: 'MainAbout',
  serverCacheKey: () => 'MainAbout',
  components: { AppSection },
};
</script>

<style lang="less">
@import "./styles/index.less";
</style>
