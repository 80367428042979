import { reactive, toRefs } from '@nuxtjs/composition-api';
import useHttpClient from '@/compositions/useHttpClient';

const useAuthorsList = () => {
  const httpClient = useHttpClient();

  const localState = reactive({
    authorsList: [],
    isLoadingAuthorsList: false,
  });

  const getAuthorsList = async (payload = {}) => {
    localState.isLoadingAuthorsList = true;
    await httpClient.author.getAuthorsList(payload)
      .then(({ list }) => {
        localState.authorsList = list;
      })
      .catch(() => {
        localState.authorsList = [];
      })
      .finally(() => {
        localState.isLoadingAuthorsList = false;
      });
  };

  return {
    ...toRefs(localState),
    getAuthorsList,
  };
};

export default useAuthorsList;
