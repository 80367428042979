<template>
  <div class="app-journal">
    <div class="app-journal__main">
      <div
        v-for="item in 4"
        :key="item"
      >
        <content-placeholders rounded>
          <content-placeholders-img class="app-journal__image-block" />
        </content-placeholders>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AppJournalSkeleton',
};
</script>

<style lang="less">
.app-journal {
  &__main {
    @media @mobile {
      display: flex;
      flex-direction: column;
      gap: @size-x5;
    }

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: @size-x6;
    grid-row-gap: @size-x5;
  }

  &__image-block {
    @media @mobile {
      min-height: 169px;
    }

    min-height: 320px;
  }
}
</style>
