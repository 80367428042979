import { onMounted, reactive, toRefs } from '@nuxtjs/composition-api';
import useJournal from '@/compositions/journal/useJournal';

const useJournalSection = () => {
  const journal = useJournal();

  const params = reactive({
    rows: [
      {
        activeTab: 'news',
        isLoading: false,
        list: [],
        tabs: {
          news: {
            key: 'news',
            title: 'Новости',
            link: '/news/',
          },
          blog: {
            key: 'blog',
            title: 'Лонгриды',
            link: '/blog/',
          },
        },
      },
      {
        activeTab: 'podborki',
        isLoading: false,
        list: [],
        tabs: {
          selection: {
            key: 'podborki',
            title: 'Подборки',
            link: '/podborki/',
          },
          recommendation: {
            key: 'people-and-books',
            title: 'Рекомендации',
            link: '/people-and-books/',
          },
        },
      },
    ],
  });

  const getContent = async (index) => {
    params.rows[index].isLoading = true;
    await journal.getCategoryList({ type: params.rows[index].activeTab, pageSize: 4 })
      .then(({ list }) => {
        params.rows[index].list = list;
      })
      .catch(() => ([]))
      .finally(() => {
        params.rows[index].isLoading = false;
      });
  };

  const setActiveTab = async (tab, index) => {
    params.rows[index].activeTab = tab;
    await getContent(index);
  };

  onMounted(() => {
    params.rows.forEach((item, index) => {
      getContent(index);
    });
  });

  return {
    ...toRefs(params),
    setActiveTab,
  };
};

export default useJournalSection;
