<template>
  <app-section-skeleton :is-title="false" :is-container="isContainer">
    <content-placeholders rounded class="banners-in-main-page">
      <content-placeholders-img class="banners-in-main-page__main-slider" />
      <div class="banners-in-main-page__sidebar">
        <content-placeholders-img class="banners-in-main-page__item" />
        <content-placeholders-img class="banners-in-main-page__item" />
      </div>
    </content-placeholders>
  </app-section-skeleton>
</template>

<script>
import AppSectionSkeleton from '@/components/AppSection/skeleton';

export default {
  name: 'BannersInMainPageSkeleton',
  components: { AppSectionSkeleton },
  props: {
    isContainer: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
