import { useContext, useRouter } from '@nuxtjs/composition-api';
import Url from 'url-parse';
import { MAIN_URL } from '@/utils/constants/globalConst';

const useUrl = () => {
  const context = useContext();
  const router = useRouter();

  const getCurrentUrl = () => {
    const urlObject = new Url(context.route.value.fullPath);
    if (!urlObject.hostname) {
      urlObject.set('hostname', context.$config.appUrl);
    }
    return urlObject.href;
  };

  const setHttpsProtocol = (url) => {
    const urlObject = new Url(url);
    if (urlObject.protocol && urlObject.protocol === 'http') {
      urlObject.protocol = 'https';
    }
    return urlObject.toString();
  };

  const checkUrl = (link) => {
    const urlObject = new Url(link);

    const isDomainExist = urlObject.origin === context.$config.appUrl
      || urlObject.origin === MAIN_URL;

    let flag = false;

    if (urlObject.pathname && isDomainExist) {
      const routerMath = router.resolve(urlObject.pathname);

      flag = !!routerMath.resolved?.name;
    }
    return flag;
  };

  const formatQueryParamsToUrlString = (query) => {
    let queryString = '';

    if (query && Object.keys(query).length) {
      const queryList = Object.keys(query);

      if (queryList.length) {
        queryList.forEach((el, index) => {
          if (index === 0) {
            queryString += `?${el}=${query[el]}`;
          } else {
            queryString += `&${el}=${query[el]}`;
          }
        });
      }
    }

    return queryString;
  };

  const checkRequiredUrlParams = (params) => {
    const urlObject = new Url(context.route.value.fullPath);
    const queryParams = urlObject.query;
    const isParamsFound = params.every(param => queryParams.includes(param));
    return isParamsFound;
  };

  return {
    getCurrentUrl,
    setHttpsProtocol,
    checkUrl,
    formatQueryParamsToUrlString,
    checkRequiredUrlParams,
  };
};

export default useUrl;
