<template>
  <app-section
    title="Журнал «Что читать»"
    :more-link="{
      title: 'Что ещё почитать?',
      link: '/journal/'
    }"
    class="app-journal"
  >
    <app-journal-list />
  </app-section>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import AppSection from '@/components/AppSection';
import AppJournalList from '@/components/AppJournal/AppJournalList';

export default defineComponent({
  name: 'AppJournal',
  components: {
    AppSection,
    AppJournalList,
  },
});
</script>
