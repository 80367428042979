<template>
  <app-section
    title="Редакции и издательства"
    is-centered
    is-full-width
    style="background: #f0f2f7;"
  >
    <ul class="publisher-list">
      <li
        v-for="item in list"
        :key="item.label"
        class="publisher-list__item"
      >
        <app-link
          class="publisher-list__link"
          :href="item.link"
        >
          <img
            class="publisher-list__image"
            :src="item.iconPath"
            :alt="item.label"
          />
        </app-link>
      </li>
    </ul>
  </app-section>
</template>

<script>
import data from '@/components/PublisherList/data.json';

import AppSection from '@/components/AppSection';

export default {
  name: 'PublisherList',
  serverCacheKey: () => 'PublisherList',
  components: { AppSection },
  data() {
    return {
      list: data,
    };
  },
};
</script>

<style lang="less">
.publisher-list {
  .list-reset;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;

  &__item {
    flex: 0 0 14%;
    max-width: 14%;

    @media @tablet {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  &__link {
    .a-reset;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: @size-x4 @size-x3;
    height: 80px;
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
