export const BANNER_SLOTS = {
  top: 'top-banner',
  mainSlider: 'main-banner',
  near: 'near-banner',
  highlight: 'highlight-banner',
  categorySale: 'category-sale-banner',
  catalog: 'catalog-banner',
  brand: 'brand-banner',
  sale: 'sale-banner',
};
