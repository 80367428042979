<template>
  <app-section
    title="Рейтинги"
    :more-link="{ title: 'Все рейтинги', link: '/rating/' }"
  >
    <rating-common-list
      :items="items"
      :is-shelf="true"
    />
  </app-section>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import AppSection from '@/components/AppSection';
import RatingCommonList from '@/components/ratingPage/RatingCommonList';

export default defineComponent({
  name: 'ShelfRating',
  components: {
    AppSection,
    RatingCommonList,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
