var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mainSlides.length)?_c('app-section',[_c('div',{staticClass:"banners-in-main-page"},[_c('embla-carousel',{staticClass:"banners-in-main-page__main-slider",attrs:{"show-dots":true,"is-auto-play":"","options":{ loop: true }}},_vm._l((_vm.mainSlides),function(slide,index){return _c('embla-slide-full-width',{key:("main-slide-" + (slide.id)),staticClass:"ddl_campaign",attrs:{"data-campaign-id":slide.id,"data-b24-banner-name":slide.name,"data-b24-banner-index":index,"data-b24-banner-id":slide.id,"data-b24-banner-link":slide.url,"data-b24-banner-slot":_vm.mainSliderSlot},nativeOn:{"click":function($event){return _vm.handlerClickBanner({
          bannerName: slide.name,
          bannerId: slide.id,
          bannerLink: slide.url,
          bannerSlot: _vm.mainSliderSlot,
          bannerIndex: index,
        })}}},[_c('app-link',{staticClass:"banners-in-main-page__link ddl_campaign_link",attrs:{"href":slide.url,"title":slide.name,"data-campaign-id":slide.id}},[_c('app-picture',{attrs:{"item":slide.image,"alt":slide.name,"title":slide.name,"fallback-image":_vm.fallbackImageMainSlider,"class-img":"banners-in-main-page__image _main","class-picture":"banners-in-main-page__picture","is-lazy-load":index !== 0}})],1)],1)}),1),(_vm.nearSlides.length)?_c('ul',{staticClass:"banners-in-main-page__sidebar"},_vm._l((_vm.nearSlides),function(nearSlide,index){return _c('li',{key:nearSlide.id,staticClass:"banners-in-main-page__item ddl_campaign",attrs:{"data-campaign-id":nearSlide.id,"data-b24-banner-name":nearSlide.name,"data-b24-banner-index":index,"data-b24-banner-id":nearSlide.id,"data-b24-banner-link":nearSlide.url,"data-b24-banner-slot":_vm.nearSlot},on:{"click":function($event){return _vm.handlerClickBanner({
          bannerName: nearSlide.name,
          bannerId: nearSlide.id,
          bannerLink: nearSlide.url,
          bannerSlot: _vm.nearSlot,
          bannerIndex: index,
        })}}},[_c('app-link',{staticClass:"banners-in-main-page__link ddl_campaign_link",attrs:{"href":nearSlide.url,"title":nearSlide.name,"data-campaign-id":nearSlide.id}},[_c('app-picture',{attrs:{"item":nearSlide.image,"alt":nearSlide.name,"title":nearSlide.name,"fallback-image":_vm.fallbackImageSalesSmall,"class-img":"banners-in-main-page__image","class-picture":"banners-in-main-page__picture","is-lazy-load":false}})],1)],1)}),0):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }