<template>
  <div class="embla-slide-full-width">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'EmblaSlideFullWidth',
};
</script>

<style lang="less">
.embla-slide-full-width {
  flex: 0 0 auto;
  width: 100%;
  position: relative;
  counter-increment: embla;
}
</style>
