<template>
  <div v-if="item" class="journal-item">
    <div class="journal-item__pic-holder">
      <app-picture
        v-if="picture"
        :item="picture"
        :alt="item.title"
        :title="item.title"
        :fallback-image="fallbackImage"
        class-img="journal-item__img"
        class-picture="journal-item__picture"
      />
    </div>
    <div
      :class="{ _hasDescription: item.description }"
      class="journal-item__content"
    >
      <h3 class="journal-item__title">
        {{ item.title }}
      </h3>
      <p
        v-if="item.description"
        class="journal-item__desc"
      >
        {{ item.description }}
      </p>
      <div class="journal-item__date">
        {{ item.date }}
      </div>
    </div>
    <app-link
      class="journal-item__link"
      :href="item.link"
    />
  </div>
</template>

<script>
import { computed, reactive, toRefs } from '@nuxtjs/composition-api';
import AppPicture from '@/components/AppPicture';
import formattedPicturesCdnBook24 from '@/utils/formatters/formattedPicturesCdnBook24';

export default {
  name: 'JournalItem',
  components: { AppPicture },
  props: {
    item: { type: Object, required: true },
    picSrc: { type: String, default: '' },
  },
  setup(props) {
    const params = reactive({
      fallbackImage: {
        type: 'fallback',
        x1: '/_ssr/img/not-image/Not_image_blog_big.png',
        x2: '/_ssr/img/not-image/Not_image_blog_big@2x.png',
        webpX1: null,
        webpX2: null,
      },
    });

    const picture = computed(() => formattedPicturesCdnBook24(props.picSrc, {
      sizePicWidth: 426,
      sizePicHeight: 250,
    }));

    return {
      ...toRefs(params),
      picture,
    };
  },
};
</script>

<style lang="less">
.journal-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: @size-x3;
  width: 426px;
  height: 320px;
  max-width: 100%;

  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: @A opacity, @A visibility;
    z-index: 1;
  }

  &:hover::before {
    opacity: 1;
    visibility: visible;
  }

  &::after {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: linear-gradient(180deg, transparent, @black);
  }

  &__pic-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: @size-x6;
    z-index: 1;
  }

  &__title {
    .h3-desktop;
    .overflow-ellipsis-lines(4);

    color: @white;
    margin-bottom: @size-x2;
  }

  &__desc {
    .h3-desktop;
    .overflow-ellipsis-lines(3);

    font-weight: normal;
    color: @white;
  }

  &__date {
    .small;

    color: @white;
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
  }

  ._big &__title {
    .h2-desktop;
  }

  ._big &__desc {
    .h2-desktop;

    font-weight: normal;
  }
}
</style>
