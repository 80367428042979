import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';

export const onScrollTo = (element, options = {}, speed = 500) => {
  Vue.nextTick(() => {
    if (element) {
      VueScrollTo.scrollTo(element, speed, options);
    }
  });
};

export const onScrollToHash = (hash, options = {}, speed = 500) => {
  Vue.nextTick(() => {
    const element = document.getElementById(hash.replace(/#/, ''));
    if (element) {
      VueScrollTo.scrollTo(element, speed, options);
    }
  });
};
