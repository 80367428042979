<template>
  <app-section
    :title="title"
    :data-b24-snippet-title="title"
    :data-b24-snippet-index="snippetIndex"
    :data-b24-snippet-names="snippetNames"
    :data-b24-snippet-links="snippetLinks"
  >
    <div class="snippet-list">
      <snippet-card
        v-for="snippet in snippets"
        :key="snippet.id"
        :snippet="snippet"
        :snippet-title="title"
        :snippet-index="snippetIndex"
      />
    </div>
  </app-section>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import { useAnalyticSnippets } from '@/compositions/analytics/useAnalyticSnippets';

import AppSection from '@/components/AppSection';
import SnippetCard from '@/components/SnippetCard';

export default defineComponent({
  name: 'SnippetList',
  components: {
    AppSection,
    SnippetCard,
  },
  props: {
    title: { type: String, default: '' },
    snippets: { type: Array, default: () => ([]) },
    snippetIndex: { type: Number, default: 1 },
  },
  setup(props) {
    const analyticSnippets = useAnalyticSnippets(props);

    return {
      ...analyticSnippets,
    };
  },
});
</script>

<style lang="less" scoped>
.snippet-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: @size-x8;

  @media @desktop {
    gap: @size-x6;
  }

  @media @tablet {
    gap: @size-x5;
  }
}
</style>
