<template>
  <app-section v-if="mainSlides.length">
    <div class="banners-in-main-page">
      <embla-carousel
        class="banners-in-main-page__main-slider"
        :show-dots="true"
        is-auto-play
        :options="{ loop: true }"
      >
        <embla-slide-full-width
          v-for="(slide, index) in mainSlides"
          :key="`main-slide-${slide.id}`"
          :data-campaign-id="slide.id"
          :data-b24-banner-name="slide.name"
          :data-b24-banner-index="index"
          :data-b24-banner-id="slide.id"
          :data-b24-banner-link="slide.url"
          :data-b24-banner-slot="mainSliderSlot"
          class="ddl_campaign"
          @click.native="handlerClickBanner({
            bannerName: slide.name,
            bannerId: slide.id,
            bannerLink: slide.url,
            bannerSlot: mainSliderSlot,
            bannerIndex: index,
          })"
        >
          <app-link
            class="banners-in-main-page__link ddl_campaign_link"
            :href="slide.url"
            :title="slide.name"
            :data-campaign-id="slide.id"
          >
            <app-picture
              :item="slide.image"
              :alt="slide.name"
              :title="slide.name"
              :fallback-image="fallbackImageMainSlider"
              class-img="banners-in-main-page__image _main"
              class-picture="banners-in-main-page__picture"
              :is-lazy-load="index !== 0"
            />
          </app-link>
        </embla-slide-full-width>
      </embla-carousel>
      <ul v-if="nearSlides.length" class="banners-in-main-page__sidebar">
        <li
          v-for="(nearSlide, index) in nearSlides"
          :key="nearSlide.id"
          class="banners-in-main-page__item ddl_campaign"
          :data-campaign-id="nearSlide.id"
          :data-b24-banner-name="nearSlide.name"
          :data-b24-banner-index="index"
          :data-b24-banner-id="nearSlide.id"
          :data-b24-banner-link="nearSlide.url"
          :data-b24-banner-slot="nearSlot"
          @click="handlerClickBanner({
            bannerName: nearSlide.name,
            bannerId: nearSlide.id,
            bannerLink: nearSlide.url,
            bannerSlot: nearSlot,
            bannerIndex: index,
          })"
        >
          <app-link
            class="banners-in-main-page__link ddl_campaign_link"
            :href="nearSlide.url"
            :title="nearSlide.name"
            :data-campaign-id="nearSlide.id"
          >
            <app-picture
              :item="nearSlide.image"
              :alt="nearSlide.name"
              :title="nearSlide.name"
              :fallback-image="fallbackImageSalesSmall"
              class-img="banners-in-main-page__image"
              class-picture="banners-in-main-page__picture"
              :is-lazy-load="false"
            />
          </app-link>
        </li>
      </ul>
    </div>
  </app-section>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

import { useAnalyticBanners } from '@/compositions/analytics/useAnalyticBanners';

import AppSection from '@/components/AppSection';
import EmblaCarousel from '@/components/EmblaCarousel';
import EmblaSlideFullWidth from '@/components/EmblaSlideFullWidth';
import AppPicture from '@/components/AppPicture';

export default defineComponent({
  name: 'BannersInMainPage',
  components: {
    AppSection,
    EmblaCarousel,
    EmblaSlideFullWidth,
    AppPicture,
  },
  props: {
    mainSlides: { type: Array, default: () => ([]) },
    nearSlides: { type: Array, default: () => ([]) },
    isAuthorized: { type: Boolean, required: true },
    locationCode: { type: String, required: true },
  },
  setup() {
    const analyticBanners = useAnalyticBanners();

    const fallbackImageMainSlider = {
      type: 'fallback',
      x1: '/_ssr/img/not-image/Not_image_sale_d.jpg',
      x2: '/_ssr/img/not-image/Not_image_sale_d@2x.jpg',
      webpX1: null,
      webpX2: null,
    };

    const fallbackImageSalesSmall = {
      type: 'fallback',
      x1: '/_ssr/img/not-image/Not_image_sale_small_d.jpg',
      x2: '/_ssr/img/not-image/Not_image_sale_small_d@2x.jpg',
      webpX1: null,
      webpX2: null,
    };

    return {
      ...analyticBanners,
      fallbackImageMainSlider,
      fallbackImageSalesSmall,
    };
  },
});
</script>

<style lang="less">
.banners-in-main-page {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  max-width: 100%;

  &__main-slider {
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    height: 320px;

    @media @tablet {
      height: 228px;
    }
  }

  &__sidebar {
    width: 420px;
    flex-basis: 420px;
    flex-shrink: 0;
    padding: 0;
    list-style: none;
    margin: 0 0 0 20px;

    @media @tablet {
      width: 300px;
      flex-basis: 300px;
    }
  }

  &__item {
    border-radius: 4px;
    width: 100%;
    max-width: 100%;
    position: relative;
    margin-top: 20px;
    height: 150px;

    @media @tablet {
      margin-top: 14px;
      height: 107px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__image {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: 4px;
    max-height: 320px;

    &._main {
      height: 320px;

      @media @tablet {
        height: 228px;
      }
    }
  }

  &__link {
    .a-reset;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__main-slider .slider-arrow._next {
    right: @size-x4;
  }

  &__main-slider .slider-arrow._prev {
    left: @size-x4;
  }
}
</style>
